import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import {
  useSetAccountPreferencesMutation,
  DoctorPreferencesInput,
  MeDocument,
  useMeQuery,
} from 'generated/legacy/graphql';
import ScanPreferences from 'pages/AccountPreferencesPage/Scan';
import TreatmentPreferences from 'pages/AccountPreferencesPage/Treatment';
import ProMonitoring from 'pages/AccountPreferencesPage/ProMonitoring';
import Notation from 'pages/AccountPreferencesPage/Notation';
import CBCTScanPreferences from 'pages/AccountPreferencesPage/CBCTScanPreferences';
import ScanIntervalDays from 'pages/AccountPreferencesPage/ScanIntervalDays';
import {
  Page,
  Container,
  Sidebar,
  Header,
  StyledSnackbar,
  FormSections,
  SidebarLink,
  SidebarTitle,
} from 'pages/AccountPreferencesPage/AccountPreferencesView.css';
import DocumentTitle from 'components/DocumentTitle';
import {
  CANDID_BRAND_NAME,
  convertToBrand,
  getBrandSupportedFeatures,
} from 'utils/brands';
import { useFlags } from 'launchdarkly-react-client-sdk';

enum ANCHOR_TAGS {
  TREATMENT = 'treatment',
  SCAN = 'scan',
  SCAN_INTERVAL = 'scan-interval',
  PRO_MONITORING = 'pro-monitoring',
  DENTAL_NOTATION = 'dental-notation',
  CBCT_SCAN = 'cbct-scan',
}

const AutoSaveForm = ({
  userId,
  initialValues,
}: {
  userId: number;
  initialValues: DoctorPreferencesInput;
}) => {
  const { values, dirty, resetForm } =
    useFormikContext<DoctorPreferencesInput>();

  const [setAccountPreferences] = useSetAccountPreferencesMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
    onCompleted: (result) => {
      const newValues =
        result.setAccountPreferences?.user?.accountPreferences?.doctor;
      if (newValues) {
        resetForm({ values: newValues });
      }
      setIsSnackbarOpen(true);
    },
  });

  useEffect(() => {
    if (dirty) {
      setAccountPreferences({
        variables: {
          input: {
            userId,
            preferences: {
              doctor: {
                ...initialValues,
                ...values,
              },
            },
          },
        },
      });
    }
  }, [values]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  return (
    <StyledSnackbar
      open={isSnackbarOpen}
      message="Preferences updated"
      autoHideDuration={2000}
      onClose={() => setIsSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  );
};

const AccountPreferencesView = () => {
  const { data: meData } = useMeQuery();
  const userInfo = meData?.me;
  const { accountPreferences, id: userId } = userInfo || {};
  const initialValues = accountPreferences?.doctor || {};
  const brand = convertToBrand(userInfo?.brandInfo?.name, CANDID_BRAND_NAME);
  const { PvsScanFlow: supportPvsScan } = getBrandSupportedFeatures(brand);
  const {
    'enable-dental-notation': enableDentalNotation,
    'enable-cbct-scan': enableCbctScanFlag,
  } = useFlags();

  return (
    <DocumentTitle title="Preferences">
      <Page>
        <Container>
          <Sidebar>
            <Header>Preferences</Header>
            <SidebarTitle>CASE SUBMISSION</SidebarTitle>
            <SidebarLink href={`#${ANCHOR_TAGS.TREATMENT}`}>
              Treatment preferences
            </SidebarLink>
            <SidebarLink href={`#${ANCHOR_TAGS.SCAN}`}>
              Scan upload preferences
            </SidebarLink>
            <SidebarTitle>MONITORING</SidebarTitle>
            <SidebarLink href={`#${ANCHOR_TAGS.SCAN_INTERVAL}`}>
              Aligner wear schedule
            </SidebarLink>
            <SidebarLink href={`#${ANCHOR_TAGS.PRO_MONITORING}`}>
              ProMonitoring patient app activation
            </SidebarLink>
            <SidebarTitle>CLINICAL</SidebarTitle>
            {enableDentalNotation && (
              <SidebarLink href={`#${ANCHOR_TAGS.DENTAL_NOTATION}`}>
                Notation system
              </SidebarLink>
            )}
            {enableCbctScanFlag && (
              <SidebarLink href={`#${ANCHOR_TAGS.CBCT_SCAN}`}>
                CBCT scan preferences
              </SidebarLink>
            )}
          </Sidebar>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // onSubmit isRequired by Formik but since we are auto-saving we don't need it
            onSubmit={() => {}}
          >
            <FormSections>
              <Header>Preferences</Header>
              <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
              <ScanPreferences
                isLoading={!userInfo}
                supportPvsScan={supportPvsScan}
                id={ANCHOR_TAGS.SCAN}
              />
              <ScanIntervalDays
                isLoading={!userInfo}
                id={ANCHOR_TAGS.SCAN_INTERVAL}
              />
              <ProMonitoring
                isLoading={!userInfo}
                id={ANCHOR_TAGS.PRO_MONITORING}
              />
              {enableDentalNotation && (
                <Notation
                  isLoading={!userInfo}
                  id={ANCHOR_TAGS.DENTAL_NOTATION}
                />
              )}
              {enableCbctScanFlag && (
                <CBCTScanPreferences
                  isLoading={!userInfo}
                  id={ANCHOR_TAGS.CBCT_SCAN}
                />
              )}
              <AutoSaveForm
                userId={Number(userId)}
                initialValues={initialValues}
              />
            </FormSections>
          </Formik>
        </Container>
      </Page>
    </DocumentTitle>
  );
};

export default AccountPreferencesView;
