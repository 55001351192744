import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Text, Tooltip, theme } from 'core/components';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  ProfileCloseButton,
  LeftCircle,
  DesktopProfileDrawer,
  Avatar,
  Flexbox,
  DesktopPatientId,
  MobileTitle,
  MobileSubtitle,
  AttributesListContainer,
  AttributeContainer,
  AttributeIcon,
  AttributeValue,
  ProfileContainer,
  AttributeDivider,
  LeftButton,
  MobileProfileDrawer,
  FlexboxColumn,
  MobileDetailArrow,
  MobileTopBarRightButton,
  MobileTopProfileBarContainer,
  VerticalText,
  VerticalSideBarContainer,
  ButtonContainer,
  OrderButton,
  ShowGuardianInfoButton,
  BlueMinusIcon,
  BluePlusIcon,
  ProfilePictureContainer,
  ProfileCloseContainer,
  DesktopTitle,
  PatientNameAndStatePanel,
  PatientStatePanel,
  ColoredBullet,
  GuardianAttributesContainer,
} from 'pages/Patient/PatientDetail/ProfileSideBar/ProfileSideBar.css';
import { mediaQueries, Button } from 'core/components';
import PencilIcon from 'assets/pencil-2.svg?react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { NotificationContext } from 'core/components';
import { useAuthContext } from 'components/AuthProvider';
import BrandChip from 'components/BrandChip/BrandChip';
import EditPatientModal from 'components/EditPatientModal/EditPatientModal';
import { getProfileBorderColor } from 'pages/Patient/utils';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'utils/customer';
import {
  fetchPatient,
  selectPatientProfilePicture,
  selectPatient,
  selectProfileInfo,
  selectHomeAddress,
  selectProviderFacingStatus,
  selectAlertStatus,
  setIsProductSelectionModalOpen,
  fetchPatientLastStepEligible,
  selectPatientBrandName,
} from 'pages/Patient/patientSlice';
import { GuardianInfoType, ProfileInfoType } from 'pages/Patient/types';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import {
  AvatarSkeleton,
  PatientNameSkeleton,
  PatientIdSkeleton,
  AttributeValueSkeleton,
  AttributeIconSkeleton,
} from 'pages/Patient/PatientDetail/Skeletons';

import PlusSvg from 'assets/ic_plus.svg?react';

import UserSvg from 'assets/icons/small/user.svg?react';
import CalaendarSvg from 'assets/icons/small/calendar.svg?react';
import EmailSvg from 'assets/icons/small/mail.svg?react';
import PhoneSvg from 'assets/icons/small/phone.svg?react';
import HomeSvg from 'assets/icons/small/home.svg?react';
import TreatingProviderSvg from 'assets/icons/small/tooth.svg?react';
import GuardianUser from 'assets/icons/small/users.svg?react';

import moment from 'moment';
import { ShowGuardianInfoButtonContainer } from 'pages/Patient/PatientDetail/ProfileSideBar/ProfileSideBar.css';
import { useIsLoading } from 'state/system';

type Attribute = {
  icon: React.ReactNode;
  value: React.ReactNode;
  toolTip: string;
  divider?: boolean;
};

type AttributesListProps = {
  patientAttributes: Attribute[];
  guardianAttributes: Attribute[];
};

type ProfileSideBarProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ResponsiveProfileProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  patientAttributes: Attribute[];
  guardianAttributes: Attribute[];
  fullName: string;
  id: string;
  picture: Maybe<string> | undefined;
  onEditPatient: () => void;
  borderColor: string;
  providerFacingStatus: string;
  showBrandChip: boolean;
  brandName: string;
};

const getPatientAttributes = (profileInfo: ProfileInfoType): Attribute[] => {
  return [
    {
      icon: <UserSvg />,
      value: profileInfo.preferredName,
      toolTip: profileInfo.preferredName,
    },
    {
      icon: <CalaendarSvg />,
      value: profileInfo.dateOfBirth,
      divider: true,
      toolTip: profileInfo.dateOfBirth,
    },

    {
      icon: <EmailSvg />,
      value: profileInfo.email,
      toolTip: profileInfo.email,
    },
    {
      icon: <PhoneSvg />,
      value: profileInfo.phone,
      toolTip: profileInfo.phone,
    },
    {
      icon: <HomeSvg />,
      value: (
        <div>
          <div>{profileInfo.displayAddress.split(',')[0]}</div>
          <div> {profileInfo.displayAddress.split(',').slice(1).join(',')}</div>
        </div>
      ),
      divider: true,
      toolTip: profileInfo.displayAddress,
    },
    {
      icon: <TreatingProviderSvg />,
      value: profileInfo.treatingProviderName,
      toolTip: profileInfo.treatingProviderName,
    },
  ];
};

const getGuardianAttributes = (guardianInfo: GuardianInfoType): Attribute[] =>
  guardianInfo
    ? [
        {
          icon: <GuardianUser />,
          value: guardianInfo?.preferredName,
          toolTip: guardianInfo?.preferredName ?? '',
        },
        {
          icon: <CalaendarSvg />,
          value: moment(guardianInfo?.birthday).format('MMM D, YYYY'),
          divider: true,
          toolTip: moment(guardianInfo?.birthday ?? '').format('MMM D, YYYY'),
        },
        {
          icon: <PhoneSvg />,
          value: formatPhoneNumber(guardianInfo?.phone ?? '')
            .formatedPhoneNumber,
          toolTip: formatPhoneNumber(guardianInfo?.phone ?? '')
            .formatedPhoneNumber,
        },
      ]
    : [];

const AttributesContainer = ({
  patientAttributes: attributes,
  guardianAttributes,
}: AttributesListProps) => {
  const { isFetchingPatient } = usePatientLoadingStates();
  const [isGuardianInfoOpen, setIsGuardianInfoOpen] = useState(false);

  return (
    <AttributesListContainer>
      <Attributes
        attributes={attributes}
        isFetchingPatient={isFetchingPatient}
      />

      {guardianAttributes.length > 0 && (
        <>
          <AttributeDivider />
          <AttributeContainer
            onClick={() => setIsGuardianInfoOpen(!isGuardianInfoOpen)}
          >
            <ShowGuardianInfoButtonContainer>
              <ShowGuardianInfoButton>
                {isGuardianInfoOpen
                  ? 'Hide guardian info'
                  : 'Show guardian info'}
              </ShowGuardianInfoButton>
              {isGuardianInfoOpen ? <BlueMinusIcon /> : <BluePlusIcon />}
            </ShowGuardianInfoButtonContainer>
          </AttributeContainer>
          {isGuardianInfoOpen && (
            <GuardianAttributesContainer>
              <Attributes
                attributes={guardianAttributes}
                isFetchingPatient={false}
              />
            </GuardianAttributesContainer>
          )}
        </>
      )}
    </AttributesListContainer>
  );
};

type AttributeProps = {
  attributes: Attribute[];
  isFetchingPatient: boolean;
};

const Attributes = ({ attributes, isFetchingPatient }: AttributeProps) => {
  {
    return (
      <>
        {attributes.map(({ icon, value, divider, toolTip }) => {
          return (
            <>
              <Tooltip title={<Text>{toolTip}</Text>} placement="top">
                <AttributeContainer>
                  {isFetchingPatient ? (
                    <AttributeIconSkeleton />
                  ) : (
                    <AttributeIcon>{icon}</AttributeIcon>
                  )}

                  {isFetchingPatient ? (
                    <AttributeValueSkeleton />
                  ) : (
                    <AttributeValue>{value}</AttributeValue>
                  )}
                </AttributeContainer>
              </Tooltip>
              {divider && <AttributeDivider />}
            </>
          );
        })}
      </>
    );
  }
};

const MobileProfile = (props: ResponsiveProfileProps) => {
  const {
    isOpen = true,
    setOpen,
    id,
    fullName,
    patientAttributes,
    guardianAttributes,
    onEditPatient,
    picture,
    borderColor,
  } = props;
  const scrollDirection = useScrollDirection();
  useEffect(() => {
    if (scrollDirection === 'down') {
      setOpen(false);
    }
  }, [scrollDirection]);
  const { isFetchingPatient, isFetchingPrism } = usePatientLoadingStates();

  return (
    <>
      {/* Static top bar */}
      <MobileTopProfileBarContainer>
        <Flexbox>
          {isFetchingPatient || isFetchingPrism ? (
            <AvatarSkeleton isMobile />
          ) : (
            <Avatar src={picture || undefined} borderColor={borderColor} />
          )}
          <FlexboxColumn>
            <MobileTitle>
              {isFetchingPatient ? <PatientNameSkeleton /> : fullName}
            </MobileTitle>
            <MobileSubtitle data-testid="pdp-profile-id">
              {isFetchingPatient ? <PatientIdSkeleton /> : `PATIENT ${id}`}
            </MobileSubtitle>
          </FlexboxColumn>
        </Flexbox>
        {!isFetchingPatient && (
          <MobileTopBarRightButton
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            <span>Details</span>
            <span>
              <MobileDetailArrow aria-hidden upArrow={isOpen} />
            </span>
          </MobileTopBarRightButton>
        )}
      </MobileTopProfileBarContainer>

      {/* Colapsible top drawer */}
      <MobileProfileDrawer variant="persistent" anchor="top" open={isOpen}>
        <AttributesContainer
          patientAttributes={patientAttributes}
          guardianAttributes={guardianAttributes}
        />
        <ButtonContainer>
          <Button
            buttonType="secondary-outline"
            isFullWidth={true}
            leftIcon={<PencilIcon />}
            onClick={() => onEditPatient()}
          >
            Edit info
          </Button>
        </ButtonContainer>
      </MobileProfileDrawer>
    </>
  );
};

const DesktopProfile = (props: ResponsiveProfileProps) => {
  const {
    isOpen = true,
    setOpen,
    id,
    fullName,
    patientAttributes,
    guardianAttributes,
    onEditPatient,
    picture,
    borderColor,
    providerFacingStatus,
    showBrandChip,
    brandName,
  } = props;
  const { isFetchingPatient, isFetchingPrism, isFetchingCases } =
    usePatientLoadingStates();
  const isFetchingLastStepEligible = useIsLoading(
    fetchPatientLastStepEligible.typePrefix
  );

  const dispatch = useDispatch();
  return (
    <>
      {/* Static left bar */}
      <VerticalSideBarContainer visible={!isOpen}>
        <VerticalText visible={!isOpen}>{fullName}</VerticalText>
      </VerticalSideBarContainer>

      {/* Colapsible left drawer */}
      <DesktopProfileDrawer variant="persistent" anchor="left" open={isOpen}>
        <ProfileCloseContainer>
          <ProfileCloseButton
            onClick={() => {
              setOpen(!isOpen);
            }}
            stroke={theme.colors.blue50}
            aria-hidden
          />
          <LeftCircle />
        </ProfileCloseContainer>
        <ProfileContainer>
          <ProfilePictureContainer>
            <LeftButton
              onClick={() => onEditPatient()}
              isHidden={isFetchingPatient}
            >
              Edit
            </LeftButton>
            {isFetchingPatient || isFetchingPrism ? (
              <AvatarSkeleton />
            ) : (
              <Avatar src={picture || undefined} borderColor={borderColor} />
            )}
            <div />
          </ProfilePictureContainer>
          <PatientNameAndStatePanel>
            <DesktopPatientId data-testid="pdp-profile-id">
              {isFetchingPatient ? <PatientIdSkeleton /> : `PATIENT ${id}`}
            </DesktopPatientId>
            <DesktopTitle>
              {isFetchingPatient ? <PatientNameSkeleton /> : fullName}
            </DesktopTitle>
            {isFetchingPatient ? (
              <PatientNameSkeleton />
            ) : (
              <PatientStatePanel>
                <ColoredBullet color={borderColor} />
                <div>
                  {/* Treatment plan is too long to fit on one line, so tweak it so it's just plan. */}
                  {providerFacingStatus.replace('Treatment plan', 'Plan')}
                </div>
              </PatientStatePanel>
            )}
            {showBrandChip && brandName && (
              <Flexbox>
                <BrandChip brandInfo={{ name: brandName }} />
              </Flexbox>
            )}
          </PatientNameAndStatePanel>

          <OrderButton
            buttonType="secondary"
            leftIcon={<PlusSvg />}
            onClick={() => dispatch(setIsProductSelectionModalOpen(true))}
            disabled={
              isFetchingPatient || isFetchingLastStepEligible || isFetchingCases
            }
          >
            Place an order
          </OrderButton>

          <AttributesContainer
            patientAttributes={patientAttributes}
            guardianAttributes={guardianAttributes}
          />
        </ProfileContainer>
      </DesktopProfileDrawer>
    </>
  );
};

const ProfileSideBar = ({ isOpen, setOpen }: ProfileSideBarProps) => {
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const ResponsiveProfile = tabletSize ? DesktopProfile : MobileProfile;
  const dispatch = useDispatch();
  const profileInfo = useSelector(selectProfileInfo);
  const picture = useSelector(selectPatientProfilePicture);
  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const providerFacingStatus = useSelector(selectProviderFacingStatus);
  const alertStatus = useSelector(selectAlertStatus);
  const profileBorderColor = useMemo(
    () => getProfileBorderColor(providerFacingStatus ?? '', alertStatus),
    [providerFacingStatus, alertStatus]
  );

  const patient = useSelector(selectPatient);
  const { userInfo } = useAuthContext();
  const showBrandChip = userInfo?.isStaff ?? false;
  const patientBrandName = useSelector(selectPatientBrandName);
  const homeAddress = useSelector(selectHomeAddress);
  const { id, fullName } = profileInfo;
  const attributes = getPatientAttributes(profileInfo);
  const guardianAttributes = getGuardianAttributes(profileInfo?.legalGuardian);

  const handleCancelEditPatient = () => {
    setIsEditPatientModalOpen(false);
  };

  const handleEditPatientSuccess = () => {
    showNotification('Successfully updated patient info', 'success');

    setIsEditPatientModalOpen(false);
    dispatch(
      fetchPatient({
        customerId: id,
      })
    );
  };

  const handleOpenEditPatient = () => {
    setIsEditPatientModalOpen(true);
  };

  if (!patient) {
    return null;
  }

  return (
    <>
      <ResponsiveProfile
        isOpen={isOpen}
        setOpen={setOpen}
        id={id}
        fullName={fullName}
        picture={picture}
        patientAttributes={attributes}
        guardianAttributes={guardianAttributes}
        onEditPatient={handleOpenEditPatient}
        borderColor={profileBorderColor}
        providerFacingStatus={providerFacingStatus ?? ''}
        showBrandChip={showBrandChip}
        brandName={patientBrandName}
      />
      <EditPatientModal
        isOpen={isEditPatientModalOpen}
        onClose={handleCancelEditPatient}
        onSuccess={handleEditPatientSuccess}
        patientData={patient}
        shippingAddress={homeAddress}
      />
    </>
  );
};

export default ProfileSideBar;
