import { CaseTypeNames } from 'constants/Case';
import {
  PartTypes,
  Manufacturers,
  TreatmentPlanToolingTypes,
  TreatmentPlanningSoftware,
} from 'generated/core/graphql';
import { isNotNil } from 'utils/typeCheck';
import api from 'state/api';

type AddTemplatedTreatmentToolingVariables = {
  patientId: number;
  krakenCaseId: string;
  software: TreatmentPlanningSoftware;
  treatmentPlanToolingType: TreatmentPlanToolingTypes;
  caseRef: string;
  treatmentPlanStagingId: string;
  caseType: CaseTypeNames;
};

const MANUFACTURABLE_PRODUCT_TYPES = ['ALIGNER_GOOD', 'RETAINER'];

// should fix the typing in the query instead of fudging it here
type OrderItem = {
  orderItemRef: string;
  quantity: number;
  order: {
    orderDate: string;
  };
  product: {
    productType: string;
  };
};

export default () => {
  const [getOrderItems, getOrderItemsState] =
    api.useLazyGetConsolidatedOrderItemsQuery();

  const [addPart, addPartState] = api.useAddPartMutation();
  const [addTreatmentPlanTooling, addTreatmentPlanToolingState] =
    api.useAddTreatmentPlanToolingMutation();
  const [generateToolingFile, generateToolingFileState] =
    api.useGenerateTemplatedTreatmentPlanToolingFileMutation();

  const executor = async ({
    caseRef,
    caseType,
    krakenCaseId,
    patientId,
    software,
    treatmentPlanStagingId,
    treatmentPlanToolingType,
  }: AddTemplatedTreatmentToolingVariables) => {
    const { fileLocation } = await generateToolingFile({
      krakenCaseId,
      patientId,
    }).unwrap();

    const orderItemResult = await getOrderItems({ caseRef }).unwrap();
    const orderItem = (orderItemResult ?? [])
      .filter(isNotNil)
      .map((oi) => oi as OrderItem)
      .filter((oi) =>
        MANUFACTURABLE_PRODUCT_TYPES.includes(oi.product?.productType ?? '')
      )
      .sort(
        (a, b) =>
          new Date(b.order.orderDate).getTime() -
          new Date(a.order.orderDate).getTime()
      )[0];

    if (!orderItem) {
      throw new Error('No order item found');
    }

    const treatmentPlanToolingResult = await addTreatmentPlanTooling({
      caseRef,
      creationResources: {
        vision: {
          manufacturingZipFileAwsLocation: fileLocation,
        },
      },
      orderItemRef: orderItem.orderItemRef,
      patientId,
      software,
      treatmentPlanToolingType,
      treatmentPlanStagingId,
    }).unwrap();
    const prerequisiteMaterialId =
      treatmentPlanToolingResult?.treatmentPlanTooling?.id;

    if (!prerequisiteMaterialId) {
      throw new Error('No treatment plan tooling id found');
    }

    return await addPart({
      caseRef,
      isTest: true,
      patientId,
      partType:
        caseType === CaseTypeNames.RETAINER
          ? PartTypes.Retainer
          : PartTypes.Aligner,
      manufacturer: Manufacturers.Kraken,
      prerequisiteMaterialIds: [prerequisiteMaterialId],
      producePart: true,
      manufacturerData: { kraken: { krakenCaseIdentifier: krakenCaseId } },
      orderItemRef: orderItem.orderItemRef,
      quantity: orderItem.quantity,
    });
  };

  const states = [
    getOrderItemsState,
    addPartState,
    addTreatmentPlanToolingState,
    generateToolingFileState,
  ];

  return [
    executor,
    {
      ...addPartState,
      isError: states.some((state) => state.isError),
      isLoading: states.some((state) => state.isLoading),
      isSuccess: states.every((state) => state.isSuccess),
      isUninitialized: states.every((state) => state.isUninitialized),
    },
  ] as const;
};
