import React from 'react';
import { Button, Modal, type } from 'core/components';
import styled from 'styled-components/macro';
import CloseSvg from 'assets/close.svg?react';

export enum CaseCloseType {
  Cancel,
  Complete,
}
type CancelCaseInProgressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  completionType: CaseCloseType;
};

export const ModalContainer = styled.div`
  width: 480px;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px; 32px;
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const HeaderTitle = styled(type.H4)`
  margin-bottom: 0;
  line-height: 20px;
`;

export const StyledCloseButton = styled(CloseSvg)`
  fill: ${({ theme }) => theme.colors.black50}}; 
  height: 24px;
  width: 24px;
`;

export const BodySection = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  overflow: auto;
`;
export const FooterSection = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  padding: 24px; 32px;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black70};
  padding: 0;
  :hover {
    background: none;
  }
`;

export const CancelCaseInProgressModal = ({
  isOpen,
  onClose,
  onConfirm,
  completionType,
}: CancelCaseInProgressModalProps) => {
  const bodyText =
    completionType === CaseCloseType.Cancel
      ? 'Are you sure you want to start a new case? You already have an in-progress case which will be canceled if you continue with this case'
      : 'Are you sure you want to start a new case? This will complete the patient’s current case and you will no longer be able to place additional orders based on that case.';
  return (
    <Modal onClose={onClose} hideCloseButton={true} isOpen={isOpen}>
      <ModalContainer>
        <HeaderSection>
          <HeaderTitle>
            {completionType === CaseCloseType.Cancel ? 'Cancel' : 'Complete'}{' '}
            in-progress case
          </HeaderTitle>
          <button onClick={onClose}>
            <StyledCloseButton />
          </button>
        </HeaderSection>
        <BodySection>{bodyText}</BodySection>
        <FooterSection>
          <StyledCancelButton buttonType="text" onClick={onClose}>
            Nevermind
          </StyledCancelButton>
          <Button buttonType="secondary" onClick={onConfirm}>
            Yes, continue
          </Button>
        </FooterSection>
      </ModalContainer>
    </Modal>
  );
};

export default CancelCaseInProgressModal;
