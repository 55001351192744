import React from 'react';
import { Loading } from 'core/components';

import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import {
  ProtocolToggleContainer as CBCTToggleContainer,
  StyleToggle,
} from 'pages/AccountPreferencesPage/Treatment/Footer.css';
import { useFormikContext } from 'formik';
import { DoctorPreferencesInput } from 'generated/legacy/graphql';
type CBCTScanPreferencesProps = {
  isLoading: boolean;
  id?: string;
};

const CBCTScanPreferences = ({ isLoading, id }: CBCTScanPreferencesProps) => {
  const { setFieldValue, values } = useFormikContext<DoctorPreferencesInput>();
  return (
    <PreferenceSection
      title="CBCT scan preferences"
      description="Choose your preferred CBCT scan settings"
      id={id}
    >
      {isLoading ? (
        <Loading isCentered />
      ) : (
        <>
          <CBCTToggleContainer>
            <div>
              <div>Enabled CBCT Scan</div>
            </div>
            <div>
              <StyleToggle
                name="enableCbctScan"
                type="checkbox"
                checked={!!values.enableCbctScan}
                onChange={(e) => {
                  setFieldValue('enableCbctScan', e.target.checked);
                }}
              />
            </div>
          </CBCTToggleContainer>
        </>
      )}
    </PreferenceSection>
  );
};

export default CBCTScanPreferences;
