import React from 'react';
import { Loading } from 'core/components';

import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import { DentalNotationOptions } from 'generated/legacy/graphql';

import { FormikRadioGroup } from 'components/FormikForms';

type NotationProps = {
  isLoading: boolean;
  id?: string;
};

const Notation = ({ isLoading, id }: NotationProps) => {
  const options = [
    {
      displayValue: 'Universal',
      value: DentalNotationOptions.Universal,
    },
    {
      displayValue: 'Palmer',
      value: DentalNotationOptions.Palmer,
    },
    {
      displayValue: 'FDI',
      value: DentalNotationOptions.Fdi,
    },
  ];

  return (
    <PreferenceSection
      title="Notation system"
      description="All tooth charts will use your chosen notation system"
      id={id}
    >
      {isLoading ? (
        <Loading isCentered />
      ) : (
        <FormikRadioGroup
          type="radio"
          options={options}
          name={'dentalNotation'}
        />
      )}
    </PreferenceSection>
  );
};

export default Notation;
