import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { fetchOrder, fetchOrderItemsShipping } from 'api/orders';
import { Grid, NotificationContext } from 'core/components';
import * as Sentry from '@sentry/react';
import ChevronRightSVG from 'assets/ic_chevron-right.svg?react';
import {
  ContainerBody,
  ContainerHeader,
  MainText,
  Note,
  StyledTable,
  SubText,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';
import { Skeleton } from 'components/Modals/OrderShippingModal/Skeleton';
import {
  OrderItemsWithShipment,
  OrderShippingProps,
} from 'pages/OrthoPrism/types';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetShipmentForOrderItemDocument,
  GetShipmentForOrderItemQuery,
  GetShipmentForOrderItemQueryVariables,
  GetReturnShipmentForOrderItemDocument,
  GetReturnShipmentForOrderItemQuery,
  GetReturnShipmentForOrderItemQueryVariables,
  ShippingTrackerStatusChoices,
} from 'generated/core/graphql';
import Sidebar from 'components/Modals/OrderShippingModal/Sidebar';
import api from 'state/api';

export type CoreShippingDetails = {
  id: number | undefined;
  shipmentRef: string | undefined;
  shipDate: string | null | undefined;
  shipped_at: string | null | undefined;
  deliveryDate: string | null | undefined;
  delivered_at: string | null | undefined;
  tracking_id: string | null | undefined;
  status: ShippingTrackerStatusChoices | undefined;
  carrier: string | null | undefined;
};

const OrderShipping = ({
  internal = false,
  caseRef,
  orderItems = [],
  incitingOrderItemRef,
  patientName = '',
}: OrderShippingProps) => {
  const { 'fetch-consolidated-order-items': enableConsolidatedOrderItems } =
    useFlags();
  const isOrthoPrism = window.location.pathname.includes('/ortho-prism');
  const { showNotification } = useContext(NotificationContext);
  const [fetchedOrderItems, setFetchedOrderItems] = useState<
    Array<OrderItemsWithShipment>
  >([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFetchingShipment, setIsFetchingShipment] = useState<boolean>(false);
  const [orderSummaries, setOrderSummaries] = useState<Array<any>>([]);
  const [errorFetchCoreShipment, setErrorFetchCoreShipment] =
    useState<boolean>(false);
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<OrderItemsWithShipment>();
  const [selectedOrderItemIndex, setSelectedOrderItemIndex] =
    useState<number>(0);
  const [getShipmentForOrderItem, { data: coreShipmentOutbound }] = useGQLQuery<
    GetShipmentForOrderItemQuery,
    GetShipmentForOrderItemQueryVariables
  >(GetShipmentForOrderItemDocument);

  const [getOrderItems] = api.useLazyGetOrderItemsQuery();
  const [getReturnShipmentForOrderItem, { data: coreShipmentInbound }] =
    useGQLQuery<
      GetReturnShipmentForOrderItemQuery,
      GetReturnShipmentForOrderItemQueryVariables
    >(GetReturnShipmentForOrderItemDocument);
  const orderItemsRefs = orderItems?.map((a) => a?.orderItemRef) || [];
  useEffect(() => {
    const getShipmentsFromCore = async (orderItemRef: string) => {
      setIsFetchingShipment(true);
      if (!orderItemRef) {
        setIsFetchingShipment(false);
        return;
      }
      const outbound = await getShipmentForOrderItem({
        orderItemRef,
      });
      await getReturnShipmentForOrderItem({
        orderItemRef,
      });
      if (!outbound) {
        setErrorFetchCoreShipment(true);
        Sentry.captureException("Couldn't fetch shipment from core");
      } else {
        setErrorFetchCoreShipment(false);
      }
      setIsFetchingShipment(false);
    };

    if (selectedOrderItem) {
      getShipmentsFromCore(selectedOrderItem?.order_item_ref);
    }
  }, [selectedOrderItem]);

  const getOrdersData = async () => {
    if (!caseRef && !orderItems?.length && !incitingOrderItemRef) {
      return;
    }
    if (incitingOrderItemRef) {
      orderItemsRefs.push(incitingOrderItemRef);
    }

    setIsFetching(true);
    try {
      let fetchedOrderItems = null;

      if (enableConsolidatedOrderItems) {
        const response = await getOrderItems({
          caseRef,
          ...(orderItemsRefs.length > 0 && {
            orderItemRefs: orderItemsRefs as string[],
          }),
        });

        fetchedOrderItems =
          response?.data?.map((item) => ({
            order_id: Number(item?.order?.orderId),
            short_order_ref: item?.order?.shortOrderRef,
            product_name: item?.product?.name,
            product_type: item?.product?.productType,
            quantity: item?.quantity,
            product_sku: item?.product?.sku,
            order_item_ref: item?.orderItemRef,
            order_date: item?.order?.orderDate,
            shipping_address: {
              name: item?.shippingAddress?.name,
              address_line_1: item?.shippingAddress?.addressLine1,
              address_line_2: item?.shippingAddress?.addressLine2,
              city: item?.shippingAddress?.city,
              country_code: item?.shippingAddress?.countryCode,
              phone: item?.shippingAddress?.phone,
              state_code: item?.shippingAddress?.stateCode,
              zip: item?.shippingAddress?.zip,
            },
            shipping_address_type: item?.shippingAddressType,
            sent_patient_shipping_update: item?.sentPatientShippingUpdate,
          })) || [];
      } else {
        fetchedOrderItems = await fetchOrderItemsShipping(
          orderItemsRefs,
          caseRef
        );
      }

      const sortedOrderItems = fetchedOrderItems.sort(
        (a: OrderItemsWithShipment, b: OrderItemsWithShipment) =>
          new Date(b.order_date).getTime() - new Date(a.order_date).getTime()
      );

      if (sortedOrderItems.length > 0) {
        // Not all order item come with shipping information, so we attempt to look for the ALIGNER_GOOD which should alway have shipping information
        const initialSelectedOrderItem =
          sortedOrderItems.find(
            (a: OrderItemsWithShipment) => a.product_type === 'ALIGNER_GOOD'
          ) || sortedOrderItems[0];
        setSelectedOrderItem(initialSelectedOrderItem);
        setSelectedOrderItemIndex(
          sortedOrderItems.findIndex(
            (a: OrderItemsWithShipment) =>
              a.order_item_ref === initialSelectedOrderItem.order_item_ref
          ) || 0
        );
      }

      setFetchedOrderItems(sortedOrderItems);
      const orderIds = sortedOrderItems.map(
        (item: OrderItemsWithShipment) => item.order_id
      );
      if (!enableConsolidatedOrderItems) {
        const orderSummaries = await Promise.all(
          orderIds.map((orderId: string) => fetchOrder(orderId))
        );
        setOrderSummaries(orderSummaries);
      }
    } catch (error) {
      showNotification(`Failed to load order items: ${error}`, 'error');
    }
    setIsFetching(false);
  };

  const getCoreShippingDetails = (
    coreShipment:
      | GetShipmentForOrderItemQuery['getShipmentForOrderItem']
      | GetReturnShipmentForOrderItemQuery['getReturnShipmentForOrderItem']
  ): CoreShippingDetails => {
    return {
      id: selectedOrderItem?.order_id, // We're passing in the Order ID here because the inbound shipment id is not meant to be displayed. The new UI will not include this "hack"
      shipmentRef: coreShipment?.id,
      shipDate: coreShipment?.tracker?.shipDate,
      shipped_at: coreShipment?.tracker?.shipDate,
      deliveryDate: coreShipment?.tracker?.deliveryDate,
      delivered_at: coreShipment?.tracker?.deliveryDate,
      tracking_id: coreShipment?.tracker?.trackingId,
      status: coreShipment?.tracker?.status,
      carrier: coreShipment?.tracker?.carrier,
    };
  };

  useEffect(() => {
    getOrdersData();
  }, [caseRef]);

  const renderOrderItems = () => {
    const columns = [
      {
        name: enableConsolidatedOrderItems ? 'short_order_ref' : 'order_id',
        label: enableConsolidatedOrderItems ? 'Order ref' : 'Order ID',
        options: {
          customBodyRender: () => {
            const displayValue = enableConsolidatedOrderItems
              ? selectedOrderItem?.short_order_ref
              : selectedOrderItem?.order_id;
            if (isOrthoPrism) {
              return (
                <a
                  href={`${import.meta.env.VITE_REACT_APP_API_LEGACY}/admin/orders/order/${selectedOrderItem?.order_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {displayValue}
                </a>
              );
            }
            return displayValue;
          },
          setCellProps: () => {
            return {
              style: {
                width: '14%',
              },
            };
          },
        },
      },
      {
        name: 'product_name',
        label: 'Product',
      },
      {
        name: 'quantity',
        label: 'Quantity',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '5%',
              },
            };
          },
        },
      },
      {
        name: 'product_sku',
        label: 'SKU',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '10%',
              },
            };
          },
        },
      },
      {
        name: 'order_item_ref',
        label: 'Order item ref',
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRender: () => <ChevronRightSVG />,
          setCellProps: () => {
            return {
              style: {
                width: '5%',
              },
            };
          },
        },
      },
      {
        name: 'coupon_code',
        label: 'coupon_code',
        options: {
          display: false,
        },
      },
      {
        name: 'inbound_shipment',
        label: 'inbound_shipment',
        options: {
          display: false,
        },
      },
      {
        name: 'product_type',
        label: 'product_type',
        options: {
          display: false,
        },
      },
      {
        name: 'shipment',
        label: 'shipment',
        options: {
          display: false,
        },
      },
    ];

    return (
      <StyledTable
        data={fetchedOrderItems}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: true,
          selectableRowsOnClick: true,
          selectableRows: 'single',
          rowsSelected: [selectedOrderItemIndex],
          pagination: false,
          fixedHeader: true,
          fixedSelectColumn: true,
          responsive: 'standard',
          selectToolbarPlacement: 'none',
          onRowSelectionChange: (_allRowsSelected: any, _: any) => {
            // This is the array of value fromm the table, hence no type here
            const onClickIndex = _allRowsSelected[0].index;
            if (onClickIndex === selectedOrderItemIndex) {
              return;
            }
            setSelectedOrderItem(fetchedOrderItems[onClickIndex]);
            setSelectedOrderItemIndex(onClickIndex);
          },
        }}
      />
    );
  };

  return (
    <ContainerBody>
      <ContainerHeader>
        <MainText>Orders & Shipping</MainText>
        <SubText>{!internal ? `${patientName}` : null}</SubText>
      </ContainerHeader>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12} lg={8}>
            <Note style={{ marginLeft: '1rem' }}>
              NOTE: Aligner Product represents the "good" which has the shipment
              information. Aligner Kit represents the "service" which has the
              payment information
            </Note>
            {renderOrderItems()}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Sidebar
              selectedOrderItem={selectedOrderItem}
              coreShipmentOutboundDetails={getCoreShippingDetails(
                coreShipmentOutbound?.getShipmentForOrderItem
              )}
              coreShipmentInboundDetails={getCoreShippingDetails(
                coreShipmentInbound?.getReturnShipmentForOrderItem
              )}
              errorFetchCoreShipment={errorFetchCoreShipment}
              orderSummaries={orderSummaries}
              isFetchingShipment={isFetchingShipment}
              internal={internal}
            />
          </Grid>
        </Grid>
      )}
    </ContainerBody>
  );
};

export default OrderShipping;
