import React, { CSSProperties, ReactNode } from 'react';
import { useField, useFormikContext } from 'formik';
import Autosuggest from 'react-autosuggest';
import { ValueType, ActionTypes } from 'react-select';
import { FormControl } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment'; // Required to return a Moment date from the DatePicker
import styled from 'styled-components/macro';
import { colors, DatePicker } from 'core/components';
import SparkleIcon from 'assets/sparkle.svg?react';
import { DentalNotationOptions } from 'generated/legacy/graphql';

import {
  CheckboxLabel,
  ErrorText,
  Input,
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
  TextArea,
} from 'styles/inputs.css';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import ToothChart from 'components/ToothChart/ToothChart';
export type FormikOption<T = string | number> = {
  value: T;
  displayValue: string;
};

export type ReactSelectOption<T = string | number> = {
  value: T;
  label: string;
};

export enum ValidateOn {
  Change,
  Blur,
}

type SelectProps = {
  options: FormikOption[];
  showDefaultValue?: boolean;
  multiple?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onSelectChange?: (e: any) => void;
  onInputChange?: (input: string) => void;
};

type RadioGroupProps = {
  options: FormikOption[];
} & InputFieldProps;

type InputFieldProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  onClear?: () => void;
  type: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  style?: CSSProperties;
  maxLength?: number;
  disabled?: boolean;
  validate?: (arg0: string) => string | undefined;
  value?: string | string[];
  testId?: string;
  defaultAnswer?: string | undefined | null;
  //Whether validation happens on change, or  on blur
  validateOn?: ValidateOn;
};

export type RenderSuggestionsContainerProps = {
  containerProps: React.HTMLProps<HTMLDivElement>;
  children: ReactNode | ReactNode[];
  query: string;
};

type AutosuggestProps<T> = {
  className?: string;
  name: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  suggestions: T[];
  validate?: (arg0: string) => string | undefined;
  onChange: (
    e: React.ChangeEvent<any>,
    params: Autosuggest.ChangeEvent
  ) => void;
  onSuggestionsFetchRequested: (input: {
    reason: string;
    value: string;
  }) => void;
  onSuggestionsClearRequested: () => void;
  renderSuggestion: (suggestion: T) => React.ReactElement;
  renderSuggestionsContainer?: (
    input: RenderSuggestionsContainerProps
  ) => React.ReactElement;
  onSuggestionSelected: (event: React.FormEvent<any>, data: any) => void;
  getSuggestionValue: (suggestion: T) => string;
  testId?: string;
  disabled?: boolean;
};

type InputWithIconFieldProps = {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
} & InputFieldProps;

type FormikToothChartProps = {
  name: string;
  validate?: (arg0: string[]) => string | undefined;
  disabled?: boolean;
  dentalNotation?: DentalNotationOptions;
};

const FormikInput = styled.div`
  .label {
    line-height: 24px;
  }
`;

const FormikAutocompleteWrapper = styled.div<{
  disabled?: boolean;
}>`
  ${({ disabled }) => `
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    line-height: inherit;
    border: 1px solid ${colors.black20};
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out;
    ${disabled ? `color: rgb(144,144,144);` : ``}
    background-color: ${colors.white};
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 41px;
    width: 100%;
    border: 1px solid ${colors.black20};
    background-color: ${colors.white};
    border-bottom-left-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 4px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`}
`;

const Container = styled.div`
  position: relative;

  input {
    padding-left: 2.5rem;
  }
`;

const IconContainer = styled.div<{
  position: 'leading' | 'trailing';
}>`
  ${({ position }) => (position === 'leading' ? 'left: 0;' : 'right: 0;')};
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  position: absolute;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.black20};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.black20};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.black20};
  }
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiFormHelperText-root {
    font-size: 14px;
    padding-left: 0;
    padding-top: 6px;
    margin: 0;
  }
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  input {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
`;

const StyledSparkleIcon = styled(SparkleIcon)`
  padding-bottom: 3px;
`;

const transformToReactSelectOptions = (
  options: FormikOption[]
): ReactSelectOption[] => options.map(transformToReactSelectOption);

const transformToReactSelectOption = (
  option: FormikOption
): ReactSelectOption => ({ value: option.value, label: option.displayValue });

/**
 * Formik Input field wrapper wraps an input tag with a label and an error message
 */
export const FormikInputWrapper = ({
  autoComplete,
  autoFocus,
  className,
  name,
  type,
  validate,
  label,
  style,
  placeholder,
  maxLength,
  onBlur,
  onChange,
  disabled = false,
  testId,
  value,
}: InputFieldProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  return (
    <FormikInput className={className}>
      <Label style={style} htmlFor={id} data-testid="form-label">
        {label}
      </Label>
      <Input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        {...field}
        onBlur={onBlur || field.onBlur}
        onChange={onChange || field.onChange}
        hasError={hasError}
        id={id}
        type={type}
        value={value ?? field.value}
        data-testid={testId}
      />
      {hasError ? (
        <ErrorText data-testid={testId && `${testId}-error`}>
          {meta.error}
        </ErrorText>
      ) : null}
    </FormikInput>
  );
};

export const AutoSuggestWrapper = <T,>({
  className,
  name,
  label,
  placeholder,
  suggestions,
  validate,
  onChange,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  renderSuggestion,
  renderSuggestionsContainer,
  getSuggestionValue,
  onSuggestionSelected,
  testId,
  disabled,
}: AutosuggestProps<T>) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = meta.touched && !!meta.error;
  const inputProps = {
    placeholder,
    value: field.value ?? '',
    onChange: onChange || field.onChange,
    name: field.name,
    disabled,
  };
  return (
    <FormikAutocompleteWrapper className={className} disabled={disabled}>
      <Label data-testid="form-label" htmlFor={id}>
        {label}
      </Label>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        id={id}
        disabled={disabled}
        data-testid={testId}
      />
      {hasError ? (
        <ErrorText data-testid={testId && `${testId}-error`}>
          {meta.error}
        </ErrorText>
      ) : null}
    </FormikAutocompleteWrapper>
  );
};

export const FormikTextAreaWrapper = ({
  testId,
  className,
  name,
  validate,
  label,
  style,
  placeholder,
  disabled = false,
}: InputFieldProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  return (
    <FormikInput className={className}>
      <Label data-testid="form-label" style={style} htmlFor={id}>
        {label}
      </Label>
      <TextArea
        data-testID={testId}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        hasError={hasError}
        id={id}
        name={name}
      />
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormikInput>
  );
};

export const FormikRichTextEditorWrapper = ({
  testId,
  className,
  name,
  validate,
  label,
  style,
}: InputFieldProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  const { setFieldValue } = useFormikContext();
  return (
    <FormikInput className={className}>
      <Label data-testid="form-label" style={style} htmlFor={id}>
        {label}
      </Label>
      <RichTextEditor
        content={field.value}
        data-testID={testId}
        onChange={(s: string) => {
          setFieldValue(name, s);
        }}
      />
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormikInput>
  );
};

export const FormikSelectWrapper = ({
  className,
  name,
  validate,
  label,
  style,
  options,
  showDefaultValue = false,
  placeholder,
  isClearable = false,
  multiple = false,
  disabled = false,
  testId,
  menuPlacement = 'auto',
  onInputChange = () => {},
  onSelectChange,
  onClear = () => {},
  value,
}: Omit<InputFieldProps, 'value'> &
  SelectProps & {
    value?: FormikOption<string | number> | FormikOption<string | number>[];
  }) => {
  const [field, meta] = useField({ name, validate });
  const { setFieldValue } = useFormikContext();

  const updatedOptions = transformToReactSelectOptions(options);
  if (!field.value && showDefaultValue && updatedOptions[0]) {
    setFieldValue(field.name, updatedOptions[0].value);
  }
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;
  const onChange = (
    option: ValueType<ReactSelectOption | ReactSelectOption[]>,
    { action }: { action: ActionTypes }
  ) => {
    if (action === 'clear') {
      onClear();
    }

    onSelectChange && onSelectChange(option);
    if (!option) {
      setFieldValue(field.name, option);
    } else {
      setFieldValue(
        field.name,
        multiple
          ? (option as ReactSelectOption[]).map(
              (item: ReactSelectOption) => item.value
            )
          : (option as ReactSelectOption).value
      );
    }
  };

  const getValue = () => {
    if (updatedOptions) {
      const defaultValue = showDefaultValue ? updatedOptions[0] : '';
      return multiple
        ? updatedOptions.filter(
            (option) => field.value?.indexOf(option.value) >= 0
          )
        : updatedOptions.find((option) => option.value === field.value) ||
            defaultValue;
    } else {
      return multiple ? [] : ('' as any);
    }
  };

  return (
    <FormikInput className={className}>
      <Label data-testid="form-label" style={style} htmlFor={id}>
        {label}
      </Label>
      <StyledReactSelect
        placeholder={placeholder}
        styles={CustomerReactSelectStyles}
        options={updatedOptions}
        isDisabled={disabled}
        hasError={hasError}
        id={id}
        onChange={onChange}
        name={name}
        value={value ?? getValue()}
        isMulti={multiple}
        isClearable={isClearable}
        onInputChange={onInputChange}
        menuPlacement={menuPlacement}
      />
      {hasError ? (
        <ErrorText data-testid={testId && `${testId}-error`}>
          {meta.error}
        </ErrorText>
      ) : null}
    </FormikInput>
  );
};

export const FormikCheckboxWrapper = ({
  className,
  name,
  validate,
  label,
  style,
  disabled = false,
}: InputFieldProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  return (
    <FormikInput className={className}>
      <CheckboxLabel style={style} htmlFor={id}>
        <Input
          checked={field.value}
          disabled={disabled}
          {...field}
          id={id}
          type="checkbox"
        />
        {label}
      </CheckboxLabel>
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormikInput>
  );
};

export const FormikInputWithIcon = ({
  leadingIcon,
  trailingIcon,
  ...props
}: InputWithIconFieldProps) => (
  <Container>
    <IconContainer position="leading">{leadingIcon}</IconContainer>
    {props.value !== undefined && props.value !== '' ? (
      <IconContainer
        position="trailing"
        onClick={() => props.onClear && props.onClear()}
      >
        {trailingIcon}
      </IconContainer>
    ) : null}
    <FormikInputWrapper {...props} />
  </Container>
);

export const FormikInputWithTrailingIcon = ({
  trailingIcon,
  ...props
}: InputWithIconFieldProps) => (
  <Container>
    <IconContainer position="trailing">{trailingIcon}</IconContainer>
    <FormikInputWrapper {...props} />
  </Container>
);

export const FormikDatePicker = ({
  name,
  label,
  type,
  validate,
  style,
  placeholder,
  disabled = false,
}: InputFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, validate });

  return (
    <FormikInput>
      <Label style={style} htmlFor={name}>
        <div data-testid="form-label" className="label">
          {label}
        </div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StyledFormControl fullWidth>
            <StyledKeyboardDatePicker
              {...field}
              disabled={disabled}
              style={style}
              type={type}
              placeholder={placeholder}
              autoOk
              inputVariant="outlined"
              format="MM/DD/YYYY"
              onChange={(selection: MaterialUiPickersDate) =>
                setFieldValue(field.name, selection?.format('YYYY-MM-DD'))
              }
              variant="inline"
            />
            {meta.touched && meta.error ? (
              <ErrorText>{meta.error}</ErrorText>
            ) : null}
          </StyledFormControl>
        </MuiPickersUtilsProvider>
      </Label>
    </FormikInput>
  );
};

export const FormikDatePickerNoHeader = ({
  name,
  label,
  type,
  validate,
  style,
  placeholder,
  disabled = false,
  validateOn = ValidateOn.Change,
}: InputFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, validate });

  return (
    <FormikInput>
      <Label data-testid="form-label" style={style} htmlFor={name}>
        <div className="label">{label}</div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StyledFormControl fullWidth>
            <DatePicker
              {...field}
              disabled={disabled}
              style={style}
              type={type}
              placeholder={placeholder}
              autoOk
              inputVariant="outlined"
              format={'MM/DD/YYYY'}
              maxDateMessage="Pick date before today"
              //Handles when the user selects a date, or entered a valid date
              onChange={(selection: MaterialUiPickersDate) => {
                const shouldSet =
                  validateOn === ValidateOn.Blur
                    ? selection && selection?.isValid()
                    : selection;
                if (shouldSet) {
                  setFieldValue(field.name, selection?.format('YYYY-MM-DD'));
                }
              }}
              //Handles when the user leaves the form field
              onBlur={(e) => {
                if (validateOn === ValidateOn.Blur) {
                  setFieldValue(field.name, e.target.value, false);
                }
              }}
              variant="inline"
              disableToolbar
            />
            {meta.error && meta.touched ? (
              <ErrorText>{meta.error}</ErrorText>
            ) : null}
          </StyledFormControl>
        </MuiPickersUtilsProvider>
      </Label>
    </FormikInput>
  );
};

const Radio = styled.input`
  margin-right: 0.75rem;
  cursor: pointer;
  appearance: radio;
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
  transform: scale(1.3);
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 4px;
  }
`;

const RadioLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.default};
  cursor: pointer;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    line-height: 1.5;
  }
`;

const RadioGroup = styled.div<{
  flexDirection?: 'row' | 'column';
}>`
  display: flex;
  ${({ flexDirection }) => `
  flex-direction: ${flexDirection || 'column'};
  `}
`;

export const FormikRadioGroup = ({
  testId,
  name,
  options,
  validate,
  disabled = false,
  defaultAnswer = undefined,
}: RadioGroupProps) => {
  const [field, meta] = useField({ name, validate });
  const id = `field-${name}`;
  const hasError = !!meta.touched && !!meta.error;

  return (
    <FormikInput data-testid={testId} role="group">
      <RadioGroup>
        {options?.map((option) => {
          const isChecked = option.value === field.value;
          const markAsDefaultAnswer =
            isChecked && defaultAnswer && defaultAnswer === option.value;
          return (
            <RadioLabel key={option.value}>
              <Radio
                type="radio"
                {...field}
                name={name}
                value={option.value}
                id={id}
                checked={isChecked}
                disabled={disabled}
              />
              <div>
                {option.displayValue}
                {markAsDefaultAnswer && <StyledSparkleIcon />}
              </div>
            </RadioLabel>
          );
        })}
      </RadioGroup>
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormikInput>
  );
};

export const FormikToothChart = ({
  name,
  validate,
  disabled,
  dentalNotation,
}: FormikToothChartProps) => {
  const [field] = useField({ name, validate });
  const { setFieldValue } = useFormikContext();

  return (
    <ToothChart
      values={field.value?.map((e: string) => parseInt(e)) ?? []}
      onChange={(v) => {
        setFieldValue(
          field.name,
          v.map((e) => e.toString())
        );
      }}
      disabled={disabled}
      notation={dentalNotation}
    />
  );
};
