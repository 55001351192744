import React from 'react';
import OrderDetail from 'components/Modals/OrderShippingModal/OrderDetail';
import { OrderDetailSkeleton } from 'components/Modals/OrderShippingModal/Skeleton';
import { Grid } from 'core/components';
import AddressAssociation from 'components/Modals/OrderShippingModal/AddressAssociation';
import { useAuthContext } from 'components/AuthProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CoreShippingDetails } from 'components/Modals/OrderShippingModal/OrderShipping';

export type SidebarProps = {
  selectedOrderItem: any;
  coreShipmentOutboundDetails: CoreShippingDetails;
  coreShipmentInboundDetails?: CoreShippingDetails;
  errorFetchCoreShipment: any;
  orderSummaries: any[];
  isFetchingShipment: boolean;
  internal: boolean;
};

const Sidebar = ({
  selectedOrderItem,
  coreShipmentOutboundDetails,
  coreShipmentInboundDetails,
  errorFetchCoreShipment,
  orderSummaries,
  isFetchingShipment,
  internal,
}: SidebarProps) => {
  const context = useAuthContext();

  const { 'enable-address-association': useProdEngAddressAssociationTool } =
    useFlags();

  if (selectedOrderItem && !selectedOrderItem?.shipping_address) {
    if (
      internal &&
      useProdEngAddressAssociationTool &&
      context.checkHasAccess(['prod-eng'])
    ) {
      return (
        <Grid item xs={12} lg={12}>
          <AddressAssociation orderItem={selectedOrderItem} />
        </Grid>
      );
    }
    return (
      <Grid item xs={12} lg={12}>
        <div>No shipping address associated with this Order item.</div>
      </Grid>
    );
  }

  if (isFetchingShipment) {
    return (
      <>
        <OrderDetailSkeleton />
      </>
    );
  }

  if (errorFetchCoreShipment) {
    return (
      <>
        <div>No shipment found for this Order item.</div>
      </>
    );
  }

  return (
    <>
      {selectedOrderItem && (
        <OrderDetail
          key={selectedOrderItem.order_item_ref}
          selectedOrderItem={selectedOrderItem}
          shipmentDetail={coreShipmentOutboundDetails}
          internal={internal}
          orderSummary={orderSummaries.find(
            (summary) => summary.id === selectedOrderItem?.order_id
          )}
        />
      )}
      {coreShipmentInboundDetails && (
        <OrderDetail
          key={`${selectedOrderItem?.order_id}_inbound`}
          isInbound={true}
          shipmentDetail={coreShipmentInboundDetails}
          internal={internal}
          title="Inbound shipment details (PVS)"
        />
      )}
    </>
  );
};

export default Sidebar;
