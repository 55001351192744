export const capitalizeAWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.substring(1);

export const lowercaseAWord = (word: string) =>
  word.charAt(0).toLowerCase() + word.substring(1);

export const capitalizeWords = (words: string) =>
  words.toLowerCase().split(' ').map(capitalizeAWord).join(' ');

/**
 * e.g. 'photos_aligner_leg' -> 'Photos Aligner Leg'
 * @param componentOption
 */
export const snakeCaseToCapitalizedWords = (str: string) => {
  const wordsStr = str.split('_').join(' ');

  return capitalizeWords(wordsStr);
};

/**
 * e.g. 'photosAlignerLeg' -> 'Photos Aligner Leg'
 * @param componentOption
 */
export const camelCaseToCapitalizedWords = (str: string) => {
  const wordsStr = str.split(/(?=[A-Z])/).join(' ');

  return capitalizeWords(wordsStr);
};

/**
 * e.g. 'starter-kit-shipped' -> 'Starter Kit Shipped'
 * @param str
 */
export const dashesToCapitalizedWords = (str: string) => {
  if (!str) {
    return '-';
  }

  const wordsStr = str.split('-').join(' ');

  return capitalizeWords(wordsStr);
};

/**
 * e.g. 'onboardingAlignerLeg' -> 'onboarding_aligner_leg'
 * @param str
 */
export const camelCaseToSnakeCase = (str: string) => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

/**
 * e.g. 'onboarding_aligner_leg' -> 'onboardingAlignerLeg'
 * @param str
 */
export const snakeCaseToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/(_[a-z])/g, (group) => group.toUpperCase().replace('_', ''));

export const getDoctorInitials = (clinicianName = '') => {
  if (!clinicianName) {
    return;
  }
  const initials: string[] = [];
  const names = clinicianName.split(' ');
  names.map((name) => {
    return initials.push(name[0].toLocaleUpperCase());
  });

  return initials.splice(0, 2).join('');
};

export const toSentenceCase = (input: string) =>
  input.charAt(0).toUpperCase() + input.substring(1).toLowerCase();

/**
  Character             Example     Meaning
  char 1-3 (3 chars)    001         Key Prefix
  char 4-5 (2 chars)    55          Instance
  char 6 (1 char)       0           Reserved
  char 7-15 (9 chars)   0000WO1Zi   Unique identifier
  char 16-18 (3 chars)  AAL         Case-insensitivty checksum
 */
const salesforceAccountIdPattern = new RegExp(
  '^001[A-Za-z0-9]{12}(?:[A-Za-z0-9]{3})?$'
);

export const replaceUnderscoresWithSpaces = (input: string): string =>
  input.split('_').join(' ');

export const isValidSfAccountId = (input: string): boolean =>
  salesforceAccountIdPattern.test(input);
