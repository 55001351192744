import styled from 'styled-components';
import { alertType } from 'core/components/AlertCard/AlertCard';
import colors from 'core/styles/colors';

const alerts = {
  warning: {
    backgroundColor: colors.yellow30,
  },
  critical: {
    backgroundColor: colors.red10,
  },
  success: {
    backgroundColor: colors.green30,
  },
  default: {
    backgroundColor: colors.black10,
  },
  info: {
    backgroundColor: colors.blue05,
  },
  action: {
    backgroundColor: colors.orange10,
  },
};

const alertStrokes = {
  warning: {
    backgroundColor: colors.text70,
  },
  critical: {
    backgroundColor: colors.red50,
  },
  success: {
    backgroundColor: colors.green90,
  },
  default: {
    backgroundColor: colors.black90,
  },
  info: {
    backgroundColor: colors.blue50,
  },
  action: {
    backgroundColor: colors.orange50,
  },
};

export const Container = styled.div<{
  type: alertType;
}>`
  width: '100%';
  height: '100%';
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  /* Get variant styles from theme */
  ${({ type }) => alerts[type]};
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 16px;
`;
export const Stroke = styled.div<{
  type: alertType;
}>`
  min-width: 3px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  /* Get variant styles from theme */
  ${({ type }) => alertStrokes[type]};
`;

export const AlertHeading = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const IconContainer = styled.div`
  min-width: 1.5rem;
  min-height: 1.5rem;

  > svg {
    display: block;
  }
`;

export const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 150%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 0;
`;

export const ChildrenContainer = styled.div`
  width: 100%;
`;

export const LinkItem = styled.a`
  color: ${colors.black90};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  &:hover {
    color: ${colors.black90};
    text-decoration: underline;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinkContainer = styled.div`
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const CloseButtonContainer = styled.div`
  min-width: 1.25rem;
  min-height: 1.25rem;
  > svg {
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
`;
